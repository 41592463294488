import * as React from "react";
import tw from "twin.macro";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FaAward, FaEye, FaWallet } from "react-icons/fa";
import {
  CTA,
  Button,
  HomepageAnnouncement,
  BlogCardBlock,
  SEO,
} from "../components";

const IndexPage: React.FC = ({
  data: {
    strapiMkoAnnouncements: { isLive, ...announcementProps },
  },
}) => {
  return (
    <>
      <SEO title="Homepage" />
      <main>
        <section tw="bg-denim-50 relative text-gray-500">
          <div tw="container mx-auto pt-24">
            <div tw="flex flex-wrap px-4">
              <div tw="mb-16 px-4 text-center w-full lg:text-left lg:w-6/12">
                <h1 tw="font-extrabold text-5xl text-mkoGray-900 tracking-tight sm:text-4xl md:text-6xl lg:text-4xl xl:text-5xl">
                  <span tw="block xl:inline">Your Local</span>
                  <br tw="hidden xl:block" />
                  <span tw="block text-denim-500 xl:inline">
                    Independent Opticians
                  </span>
                </h1>
                <p tw="max-w-md mt-3 mx-auto text-lg text-mkoGray-400 sm:text-xl md:max-w-3xl md:mt-5">
                  Founded in 1973, Martyn Kemp Opticians is the largest
                  independent group of opticians in South Yorkshire.
                </p>
                <div tw="mt-10 sm:flex sm:justify-center lg:justify-start">
                  <Button
                    text="Learn About MKO"
                    to="/about"
                    colour="primary"
                    customStyles={tw`md:text-lg!`}
                  />
                </div>
              </div>
              <div tw="-mb-14 w-full lg:w-6/12 flex items-end">
                <div tw="bg-white p-4 rounded-lg shadow-2xl">
                  <StaticImage
                    layout="constrained"
                    src="../images/MKO-storefront-cropped.jpg"
                    alt="MKO Storefront"
                    tw="rounded-lg -mb-2"
                    imgStyle={{ borderRadius: "0.5rem" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {isLive && <HomepageAnnouncement {...announcementProps} />}
        <section tw="bg-white">
          <div tw="container mx-auto py-20">
            <h2 tw="capitalize leading-tight mb-12 text-4xl font-bold text-center">
              Why Choose MKO?
            </h2>
            <div tw="flex flex-wrap items-stretch">
              <div tw="w-full p-4  sm:w-4/12">
                <Link
                  to="/about"
                  tw="block px-6 py-16 rounded-lg shadow-lg h-full bg-denim-50 "
                >
                  <FaAward tw="mb-4 text-4xl text-denim-700" />
                  <h3 tw="font-bold mb-1 text-gray-900 text-lg">
                    Outstanding Reputation
                  </h3>
                  <p tw="text-base text-gray-600">
                    Over the best part of 50 years we built our excellent
                    reputation caring for over 100,000 patients. You can trust
                    us to look after your eyes.
                  </p>
                  <p tw="font-semibold mt-2 text-base text-gray-900">
                    Find Out More
                  </p>
                </Link>
              </div>
              <div tw="w-full p-4  sm:w-4/12">
                <Link
                  to="/eye-examinations"
                  tw="block px-6 py-16 rounded-lg shadow-lg h-full bg-denim-50"
                >
                  <FaEye tw="mb-4 text-4xl text-denim-700" />
                  <h3 tw="font-bold mb-1 text-gray-900 text-lg">
                    The Best in Eye Care
                  </h3>
                  <p tw="text-base text-gray-600">
                    Our highly-qualified staff are assisted by the latest
                    high-tech equipment to enable a thorough but smooth service.
                  </p>
                  <p tw="font-semibold mt-2 text-base text-gray-900">
                    Find Out More
                  </p>
                </Link>
              </div>
              <div tw="w-full p-4  sm:w-4/12">
                <Link
                  to="/eyewear"
                  tw="block px-6 py-16 rounded-lg shadow-lg h-full bg-denim-50"
                >
                  <FaWallet tw="mb-4 text-4xl text-denim-700" />
                  <h3 tw="font-bold mb-1 text-gray-900 text-lg">
                    Quality Eyewear
                  </h3>
                  <p tw="text-base text-gray-600">
                    We offer a fantastic range of quality products tailored to
                    your individual needs.
                  </p>
                  <p tw="font-semibold mt-2 text-base text-gray-900">
                    Find Out More
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section tw="bg-denim-200">
          <div tw="bg-mkoGray-500 relative">
            <div tw="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
              <StaticImage
                src="../images/homepage-glasses.jpeg"
                tw="w-full h-full"
                objectFit="cover"
                alt="Designer glasses"
              />
            </div>
            <div tw="max-w-7xl mx-auto px-4 py-12 relative sm:px-6 lg:px-8 lg:py-16">
              <div tw="md:ml-auto md:pl-10 md:w-1/2">
                <h2 tw="font-semibold max-w-screen-sm text-base text-denim-500 tracking-wider uppercase md:pr-14 lg:pr-0">
                  Quality Eyewear
                </h2>
                <p tw="font-extrabold max-w-screen-sm mt-2 text-3xl text-white tracking-tight sm:text-4xl md:pr-14 lg:pr-0">
                  Top Brands, for Everyone
                </p>
                <p tw="max-w-screen-sm mt-3 text-lg text-mkoGray-100 md:pr-14 lg:pr-0">
                  We have a wide variety of designer brands across our MKO
                  practices to cater for different styles and lifestyle
                  requirements. With such a vast range of frames, there is
                  something to suit everyone.
                </p>
                <div tw="max-w-screen-sm mt-8 md:pr-14 lg:pr-0">
                  <Button text="Browse Our Brands" to="/eyewear" />
                </div>
              </div>
            </div>
          </div>
          <div tw="bg-mkoGray-500 relative">
            <div tw="h-56 sm:h-72 md:absolute md:h-full md:right-0 md:w-1/2">
              <StaticImage
                src="../images/homepage-eye.jpeg"
                tw="w-full h-full"
                objectFit="cover"
                alt="Eye with contact lens"
              />
            </div>
            <div tw="max-w-7xl mx-auto px-4 py-12 relative sm:px-6 lg:px-8 lg:py-16">
              <div tw="md:mr-auto md:pr-10 md:w-1/2">
                <h2 tw="font-semibold max-w-screen-sm ml-auto text-base text-denim-500 tracking-wider uppercase md:pl-14 lg:pl-0">
                  Exceptional Eyecare
                </h2>
                <p tw="font-extrabold max-w-screen-sm ml-auto mt-2 text-3xl text-white tracking-tight sm:text-4xl md:pl-14 lg:pl-0">
                  Contact Lenses
                </p>
                <p tw="max-w-screen-sm ml-auto mt-3 text-lg text-mkoGray-100 md:pl-14 lg:pl-0">
                  There are many different types of contact lenses for different
                  needs. Your optometrist can assist you with finding the right
                  type of contact lenses to suit your eyes, lifestyle and
                  individual requirements.
                </p>
                <div tw="max-w-screen-sm ml-auto mt-8 md:pl-14 lg:pl-0">
                  <Button
                    text="More About Contact Lenses"
                    to="/contact-lenses"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <CTA />
        <BlogCardBlock />
      </main>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query indexPageQuery {
    strapiMkoAnnouncements {
      topic
      header
      summary
      isLive
    }
  }
`;
